<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="primary" @click="configureFormOpen = true">
            <ion-icon slot="icon-only" :ios="settingsOutline" :md="settingsSharp" color="primary"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title :size="isConfigured ? 'small' : 'large'">
          <strong>Hashtag Replay</strong>
          <div v-if="isConfigured" class="hashtag">#{{ global.tweets.state.hashtag }} ({{ currentTimeshiftTime }})</div>
        </ion-title>

        <ion-buttons v-if="isConfigured" slot="end">
          <ion-button color="primary" @click="onRefresh">
            <ion-icon slot="icon-only" :ios="refreshOutline" :md="refreshSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content ref="contentEl" :fullscreen="true">
      <Timeline v-if="isConfigured" :loading="loading" />
      <intro v-else @getstarted="configureFormOpen = true" />

      <ion-modal
        :is-open="configureFormOpen"
        css-class="configure-modal"
        @didDismiss="configureFormOpen = false"
      >
        <configure-form @start="onStart" />
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonModal, IonButtons, IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonIcon } from '@ionic/vue';
import { settingsOutline, settingsSharp, refreshOutline, refreshSharp } from 'ionicons/icons';
import ConfigureForm from '../components/ConfigureForm.vue';
import Timeline from '../components/Timeline.vue';
import Intro from '../components/Intro.vue';

export default {
  name: 'Home',

  inject: ['global'],

  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonModal,

    ConfigureForm,
    Timeline,
    Intro,
  },

  data() {
    return {
       settingsOutline,
       settingsSharp,
       refreshOutline,
       refreshSharp,

       loading: false,
       configureFormOpen: false,
    };
  },

  computed: {
    isConfigured() {
      return this.global.tweets.state.configured;
    },

    currentTimeshiftDateTime() {
      return this.global.tweets.state.currentDateTime;
    },

    currentTimeshiftTime() {
      return this.currentTimeshiftDateTime.toLocaleTimeString().slice(0, 5);
    },
  },

  methods: {
    onStart(startDate, startTime, hashtag) {
      this.global.tweets.startTimeshift(startDate, startTime, hashtag);

      this.configureFormOpen = false;
    },

    async onRefresh() {
      this.$refs.contentEl.$el.scrollToTop();

      this.loading = true;

      await this.global.tweets.fetchTweets();
      
      this.loading = false;
    }
  },
}
</script>

<style scoped>
.hashtag {
  font-style: italic;
  
  font-size: 0.9rem;
}
</style>

<style>
.configure-modal .modal-wrapper {
   height: 50%;
   top: 25%;
   width: 80%;
   max-width: 300px;
   min-height: 300px;
   position: absolute; 
   display: block;
   border-radius: 10px;
}
</style>