<template>
  <div class="timeline">
    <ion-spinner v-if="loading || renderingTweets" name="crescent" color="primary"></ion-spinner>
    <div ref="tweetWrapper"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { IonSpinner } from '@ionic/vue';

export default defineComponent({
  name: 'Timeline',

  inject: ['global'],

  components: {
    IonSpinner,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
  },

  data() {
    return {
      renderingTweets: false,
    };
  },
  watch: {
    loading(val) {
      if (val) {
        this.renderingTweets = true;
      }
    },

    'global.tweets.state.tweetBlocks': {
      deep: true,
      handler(val) { 
        if (val.length > 0) {
          this.createTweets(val[0].data.data);
        } else {
          this.clearTweets();
        }
      },
    },
  },

  methods: {
    async createTweets(tweets) {
      this.renderingTweets = true;

      const el = this.$refs.tweetWrapper;
      const beforeEl = el.firstChild;

      const container = document.createElement('div');
      container.classList.add('tweet-block');
      container.style.visibility = 'hidden';
      container.style.height = 0;

      el.insertBefore(container, beforeEl);

      const pro = tweets.map((tweet) => {
          return this.createTweet(tweet, container);
      });

      await Promise.all(pro);

      container.style.height = 'auto';
      container.style.visibility = 'visible';

      this.renderingTweets = false;
    },

    clearTweets() {
      this.$refs.tweetWrapper.innerHTML = '';
    },

    createTweet(tweet, container){
      return window.twttr.widgets.createTweet(
        tweet.id,
        container
      );
    }
  },
});
</script>

<style scoped>
.timeline {
  margin: 1rem;
  text-align: center;
}

.timeline .tweet-block {
  background: white;
}
</style>

<style>
.twitter-tweet {
  margin: 10px auto;
}
</style>