<template>
  <div>
    <ion-item>
      <ion-label position="stacked">Start Date</ion-label>
      <ion-input type="date" v-model="startDate" :min="minDate" :max="formatDate(new Date())"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Start Time</ion-label>
      <ion-input type="time" v-model="startTime"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Hashtag</ion-label>
      <ion-input type="text" v-model="hashtag"></ion-input>
    </ion-item>

    <ion-button @click="onStart" expand="block" size="large">Start</ion-button>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfigureForm',
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  setup() {
  },

  data() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 7);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);

    return {
      minDate: this.formatDate(minDate),

      startDate: this.formatDate(startDate),
      startTime: '21:00',
      hashtag: '',
    };
  },

  computed: {
    cleanHashtag() {
      return this.hashtag.replace('#', '');
    },
  },

  methods: {
    onStart() {
      this.$emit('start', this.startDate, this.startTime, this.cleanHashtag);
    },

    formatDate(v) {
      return `${v.getFullYear()}-${this.pad(v.getMonth() + 1)}-${this.pad(v.getDate())}`
    },

    pad(v) {
      return `0${v}`.slice(-2);
    },
  },
});
</script>

<style scoped>
ion-button {
  --border-radius: 0;

  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>