<template>
  <div class="intro">
    <div class="top-pad"></div>
    
    <div>
      <p>
        Follow a TV Show's hashtag from when it aired as if it was happening now.
      </p>

      <ion-button expand="block" color="primary" @click="onGetStartedClick">Get Started</ion-button>
    </div>

    <div class="bottom-pad"></div>

    <div class="credit">
      Created by <a href="http://www.stuartashworth.com" target="_blank">Stuart Ashworth</a> in 🏴󠁧󠁢󠁳󠁣󠁴󠁿
    </div>
  </div>
</template>

<script>
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Intro',
  components: {
    IonButton,
  },
  setup() {
  },

  data() {
    return {
    };
  },

  methods: {
    onGetStartedClick() {
      this.$emit('getstarted');
    },
  },
});
</script>

<style scoped>
.intro {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem;
  text-align: center;
  font-size: 1.4rem;
}

.intro .top-pad {
  flex: 1;
}

.intro .bottom-pad {
  flex: 2;
}

.intro p {
  margin-bottom: 2rem;
}

.intro ion-button {
  max-width: 300px;
  margin: 0 auto;
}

.intro .credit {
  margin: 0 0 1rem 0;
  font-size: 1rem;
}
</style>